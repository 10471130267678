<template>
  <div v-if="connector" class="me">
    <div
      class="title clicable"
      :title="$t('close')"
      @click.stop.prevent="close"
    >
      <i class="fa fa-bug"></i>
      <span>
        {{ connector.name }}
      </span>
      <i class="fa fa-angle-right pull-right" style="margin: 3px 2px 0 0"></i>
    </div>
    <div>
      <div class="form-group form-group-sm search">
        <input
          type="text"
          ref="query"
          :placeholder="$t('search')"
          class="form-control"
          v-model="filters.query"
        />
        <i class="fa fa-search"></i>
        <i class="fa fa-close clicable" @click.stop.prevent="resetFilters"></i>
      </div>
      <div class="text-center">
        <span
          class="radio"
          v-for="level in filters.levels"
          :key="level.name"
          style="padding: 0"
        >
          <label>
            <input
              type="radio"
              name="levels"
              :id="level"
              v-model="filters.current_level"
              :value="level.name"
            />
            <span>
              {{ level.name }}
            </span>
          </label>
        </span>
      </div>
    </div>
    <div class="log-container no-select">
      <div class="">
        <i class="fa fa-list-ul"></i>
        <span>
          {{ $t("events") }}
        </span>
        <span class="pull-right container-buttons">
          <!-- <span class="btn btn-xs" @click="test"> teste </span> -->
          <span @click.stop.prevent="togglePlay" :title="$t('play_or_pause')">
            <i class="clicable fa" :class="paused ? 'fa-play' : 'fa-pause'"></i>
          </span>
          <span @click.stop.prevent="download" :title="$t('export')">
            <i class="clicable fa fa-download"></i>
          </span>
          <span @click.stop.prevent="resetItems" :title="$t('reset')">
            <i class="clicable fa fa-trash"></i>
          </span>
        </span>
      </div>
      <div v-if="items.length">
        <template v-for="(item, ix) in filteredItems">
          <div :key="ix">
            <div
              class="item-title clicable"
              @click.stop.prevent="item.collapsed = !item.collapsed"
            >
              <div class="item-status-icon">
                <i :class="item.icon_class"></i>
              </div>
              <div class="item-title-text">
                <span>
                  <span class="created_at">{{ item.fmtTime }}</span>
                  {{ item.msg }}
                </span>
                <i
                  :class="item.collapsed ? 'fa-chevron-down' : 'fa-chevron-up'"
                  class="fa collapse-btn clicable"
                ></i>
              </div>
            </div>
            <div
              class="item-content"
              :class="{'item-collapsed': item.collapsed}"
            >
              <div>
                {{ item.details }}
              </div>
              <div class="created_at">
                {{ $t("created_at") }}: {{ item.fmtDateTime }}
              </div>
              <div class="raw" v-if="item.raw">
                <div
                  class="raw_message_toggle clicable"
                  @click="item.show_raw = !item.show_raw"
                >
                  <i
                    class="fa"
                    :class="item.show_raw ? 'fa-eye' : 'fa-eye-slash'"
                  ></i>
                  {{ $t("details") }}
                </div>
                <div v-if="item.show_raw">
                  {{ item.raw }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-else class="text-center">
        <i class="fa fa-refresh fa-spin"></i>
        {{ $t("waiting") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RealTimeEventViewer",
  props: {
    equipment: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      connector: this.equipment, // might be set by setConnector event or provided as a property (dashboard panel)
      filters: {
        initial_level: "all",
        current_level: "all",
        levels: [
          {
            name: "all",
            icon: ""
          },
          {
            name: "debug",
            icon: "fa fa-bug icon-status-debug"
          },
          {
            name: "info",
            icon: "fa fa-exclamation-circle icon-status-info"
          },
          {
            name: "error",
            icon: "fa fa-exclamation-triangle icon-status-error"
          }
        ],
        query: ""
      },
      items: [],
      paused: false
    };
  },
  components: {},
  computed: {
    filteredItems() {
      return this.items.filter((item) => {
        if (
          this.filters.current_level !== "all" &&
          item.level != this.filters.current_level
        ) {
          return false;
        }
        if (this.filters.query) {
          return (
            this.$utils
              .trim(item.msg)
              .toUpperCase()
              .indexOf(this.$utils.trim(this.filters.query.toUpperCase())) >= 0
          );
        }
        return true;
      });
    }
  },
  watch: {},
  methods: {
    setConnector(value) {
      if (value) {
        this.connector = value;
        this.resetItems();
        this.setupProtocol();
      } else {
        this.connector = null;
        this._mqttObserver = false;
        this.resetItems();
      }
    },
    log(value) {
      let entry = null;
      if (typeof value == "string") {
        entry = {
          collapsed: true,
          level: "info",
          msg: value,
          created_at: "",
          timestamp: "",
          fmtTime: "",
          fmtDateTime: "",
          icon_class: "fa fa-info",
          details: "",
          raw: "",
          show_raw: false
        };
      } else if (typeof value == "object") {
        entry = {
          ...{
            collapsed: true,
            level: "info",
            msg: "",
            created_at: "",
            timestamp: "",
            fmtTime: "",
            fmtDateTime: "",
            icon_class: "fa fa-info",
            details: "",
            raw: JSON.stringify(value),
            show_raw: false
          },
          ...value
        };
      }
      if (entry) {
        entry.timestamp = (entry.created_at
          ? new Date(entry.created_at)
          : new Date()
        ).toISOString();
        entry.fmtTime = moment(new Date(entry.timestamp)).format("HH:mm:ss");
        entry.fmtDateTime = moment(new Date(entry.timestamp)).format("L LTS");
        let level = this.filters.levels.find(({name}) => name == entry.level);
        if (level) {
          entry.icon_class = level.icon;
        }
        entry.details = entry.msg;
        this.items.unshift(entry);
      }
    },
    onMQTTStatus(status) {
      this.log(status);
      if (status == "CONNECTED") {
        this.$rt.mqtt.subscribeOnlyOnTopics([
          `${this.connector.mqtt_topic_prefix}/#`
        ]);
      }
    },
    onMQTTMessage($event) {
      if (this.paused) return;
      if (!$event.info) return;
      let topic = $event.info.destinationName;
      if (topic.indexOf("connector_events") >= 0) {
        this.log($event.msg);
      } else {
        var re = new RegExp(`${this.connector.mqtt_topic_prefix}/`, "ig");
        topic = topic.replace(re, "");
        let entry = {
          level: "info",
          created_at: new Date().toISOString(),
          msg: topic,
          raw: JSON.stringify($event.msg)
        };
        this.log(entry);
      }
    },
    setupProtocol() {
      if (this._mqttObserver || !this.$rt.mqtt) return;
      this._mqttObserver = true;
      this.onMQTTStatus(this.$rt.mqtt.status);
      return;
    },
    resetFilters() {
      this.filters.current_level = this.filters.initial_level;
      this.filters.query = "";
      if (this.$refs.query) {
        this.$refs.query.focus();
      }
    },
    resetItems() {
      this.$set(this, "items", []);
    },
    download() {
      let now = new Date()
        .toISOString()
        .split(".")[0]
        .replace(/[:T-]/g, "");
      let connector_name = this.$utils.asKey(this.connector.name);
      let rows = [...(this.filteredItems || [])].reverse().map((item) => {
        return `${item.timestamp} ${item.level} ${item.msg}`;
      });
      var body = rows.join("\n");
      var e = document.createElement("a");
      document.body.appendChild(e);
      e.href = "data:text/plain;charset=utf-8," + encodeURIComponent(body);
      e.download = `${connector_name}_${now}.log"`;
      e.addEventListener("click", (ev) => {
        ev.stopPropagation();
        document.body.removeChild(ev.target);
      });
      e.click();
    },
    togglePlay() {
      this.paused = !this.paused;
    },
    close() {
      this.$root.$emit("controlSidebar:setContent");
    },
    test() {
      // this.$rt.mqtt.publish(`${this.connector.mqtt_topic_prefix}/connector_events`, { level: "info", type: "resources_updated", msg: "resources_updated" })
      this.$rt.mqtt.publish(
        `${this.connector.mqtt_topic_prefix}/connector_events`,
        {
          level: "info",
          msg: "resources_updated",
          entries: [
            {
              source_id: Vue.http.options.mqtt._id,
              method: "post",
              id: response.body.id,
              type: "connector"
            }
          ]
        }
      );
      // this.$rt.mqtt.publish("0677d1c8/connector_events", { "level": "debug", "msg": "Connector(0677d1c8) online", "client_id": "0677d1c8", "created_at": "2024-05-24T15:05:30.109Z" })
    }
  },
  mounted() {
    this.resetFilters();
  },
  created() {
    this.$root.$on("setConnector", this.setConnector);
  },
  beforeCreate() {
    this._onMQTTStatus = ($e) =>
      this.onMQTTStatus && this.onMQTTStatus($e.detail);
    this._onMQTTMessage = ($e) =>
      this.onMQTTMessage && this.onMQTTMessage($e.detail);
    this.$rt.addListener("mqtt:status", this._onMQTTStatus);
    this.$rt.addListener("mqtt:message", this._onMQTTMessage);
  },
  beforeDestroy() {
    this.$rt.removeListener("mqtt:status", this._onMQTTStatus);
    this.$rt.removeListener("mqtt:message", this._onMQTTMessage);
    this.$root.$off("setConnector");
    this.setConnector();
  }
};
</script>

<style scoped>
.me {
  width: 100;
}

div.title {
  font-size: 12pt;
  padding: 5px 12px 5px 10px;
  background-color: white;
  color: #666;
  margin: 0 -5px;
  font-weight: 600;
}

div.title > i {
  vertical-align: middle;
}

div.title > span {
  padding: 5px;
}

div.search {
  position: relative;
  margin-bottom: -5px;
}
div.search > input {
  padding-left: 26px;
}

div.search > i.fa-search {
  position: absolute;
  top: 8px;
  left: 8px;
  color: #aaa;
}

div.search > i.fa-close {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #aaa;
}

span.radio {
  display: inline-block;
}
span.radio > label {
  padding-right: 10px;
}
span.radio > label > span {
  margin-left: -5px;
}
.log-container {
  padding: 2px 4px;
  border-top: 1px solid #ddd;
}

div.item-title {
  white-space: nowrap;
}
div.item-status-icon {
  display: inline-block;
  width: 16px;
  text-align: center;
  margin-left: -3px;
}
div.item-title-text {
  display: inline-block;
  width: 100%;
  margin-left: -16px;
  padding-left: 16px;
  vertical-align: top;
}

div.item-content {
  max-height: 200px;
  overflow-y: auto;
  padding: 7px 3px 0px 3px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin: 0px;
  white-space: normal;
  font-size: 10pt;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.item-collapsed {
  display: none;
}
i.collapse-btn {
  position: absolute;
  right: 0;
  background-color: white;
  color: #999;
  border-radius: 2px;
  padding: 2px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

span.created_at,
div.created_at {
  color: #999;
  font-size: 8pt;
}

.icon-status-debug {
  color: rgb(29, 158, 72);
}

.icon-status-info {
  color: rgb(68 151 217);
}

.icon-status-error {
  color: rgb(167 52 52);
}
.raw {
  font-size: 90%;
  background-color: whitesmoke;
}
.raw_message_toggle {
  font-size: 10pt;
}
.container-buttons > span {
  margin: 0 5px;
}
</style>
